import { FC } from 'react';
import { Typography } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import Flag from 'react-flagpack';
import { getCountryName } from 'utils/country';
import { useTranslation } from 'react-i18next';

interface CountryNameProps {
  readonly countryCode: string;
  readonly size?: 's' | 'm' | 'l';
}

const CountryName: FC<CountryNameProps> = ({ countryCode, size }) => {
  const flagCode = countryCode === 'GB' ? 'GB-UKM' : countryCode;
  const { t } = useTranslation();

  return (
    <ErrorBoundary fallbackRender={() => <>{t('unknown')}</>}>
      <Typography noWrap component="span">
        <Flag code={flagCode} size={size ?? 'm'} />
        {'  '}
        {getCountryName(countryCode)}
      </Typography>
    </ErrorBoundary>
  );
};

export default CountryName;

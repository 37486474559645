import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardContent, Button, Typography } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { SiteDetail, useDeleteSite } from 'api/site';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { cssVar } from 'utils/css';
import { isSiteAccess, useGetToken } from 'utils/token';

const PREFIX = 'DeleteSiteSupport';

const classes = {
  card: `${PREFIX}Card`,
  cardContent: `${PREFIX}CardContent`,
};

const StyledCard = styled(Card)({
  [`&.${classes.card}`]: {
    backgroundColor: cssVar('--color-ebb'),
    boxShadow: 'none',
  },
  [`& .${classes.cardContent}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 60rem)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    '& p': {
      paddingLeft: '1.5625rem',
      position: 'relative',
      '& svg': {
        height: '1.25rem',
        left: '0',
        position: 'absolute',
        top: '0.0625rem',
        width: '1.25rem',
      },
    },
    '& button': {
      marginTop: '1rem',
      '@media (min-width: 60rem)': {
        marginTop: '0',
      },
    },
  },
});

const DeleteSiteSupport = ({
  siteDetails,
  isWpSiteControl,
}: {
  readonly siteDetails?: SiteDetail;
  readonly isWpSiteControl: boolean;
}) => {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const token = useGetToken();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const { mutateAsync: deleteSite, isPending } = useDeleteSite(siteId);

  const handleDelete = async () => {
    await deleteSite();
    navigate('/sites');
  };

  if (siteDetails && !siteDetails.production && !isSiteAccess(token) && !isWpSiteControl) {
    return (
      <StyledCard className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography>
            <DeleteOutline />
            {t('need_to_delete')} {siteDetails?.domain ?? ''}?
          </Typography>
          <Button onClick={() => setDeleteModalOpen(true)} variant="outlined" color="secondary">
            {t('delete')}
          </Button>
          <ConfirmationDialog
            action="delete"
            id="deleteSiteModal"
            forceLoadingState={isPending}
            open={deleteModalOpen}
            title={t('delete_site_modal_title')}
            description={t('delete_site_modal_description')}
            confirmText={t('delete')}
            onConfirm={handleDelete}
            onClose={() => setDeleteModalOpen(false)}
          />
        </CardContent>
      </StyledCard>
    );
  }

  return null;
};

export default DeleteSiteSupport;
